@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/ROBOTO.ttf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

:root {
    --color1: #283277;
    --color2: #365999;
    --color3: #1e70aa;
    --color4: #71a3d6;
    --color5: #00875f;
    --color6: #2bab6e;

    --color7: #81daa4;
    --color8: #303030;
    --color9: #F7ACAC;
    --color10: #9D3AD9;
    --color11: #F0F0F0;

    --color12: #FFEE74;
    --color13: #FFAC33;
    --color14: #FF8174;
    --color15: #A974FF;
    --color16: #FFFFFF;
}
/*propertie: var( --colorX )*/


.buttonStd1{
    background-color: var(--color6)!important;
    border-color: var(--color6)!important;
    font-family: 'Roboto';
    font-weight: bold;
    border-width: 1px!important;
}
.buttonStd1:hover{ 
    transform: scale(1.1); 
    background-color: var(--color3)!important;
    border-color: var(--color3)!important;
}

.buttonStd2{
    background-color: var(--color3)!important;
    border-color: var(--color3)!important;
    font-family: 'Roboto';
    font-weight: bold;
    border-width: 1px!important;
}
.buttonStd2:hover{ 
    transform: scale(1.1); 
    background-color: var(--color6)!important;
    border-color: var(--color6)!important;
}



#sections{
    min-width: 100%;
}

.sectionTitle{
    padding-top:100px;
    text-align: center;
    font-size: 6vh;
    color: var(--color2);
    bottom: 20px;
    font-family: "Roboto";
    font-weight: bold;
}
.sectionSubtitle{
    padding-top:10px;
    text-align: justify;
    font-family: "Roboto";
    color: var(--color8);
    padding-bottom: 10px;
}

/*HEADER STYLES*/
#header_section{
    min-height: 60px;
}

.headerNavbar{
    /*min-height: 60px;*/
    color: white;
}
.headerBrand{
    top: 0px;
}
.headerToggle{
    position: absolute;
    right: 20px;
    top: 25px;
}

.linkNav{
    font-weight: bold;
    font-family: 'Roboto';
}

.linkNav:hover{
    font-size: 130%;
    font-weight: bold;
    text-shadow: var(--color6) 0.1em 0.1em 0.4em;
    font-family: 'Roboto';
}

/*INICIO STYLES*/
#inicio_section{
    background-image: url("./images/background_b.png");
	background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
}

.inicioTitle{
    padding-top: 100px;
    text-align: left;
    color: var(--color2);
    font-size: 8vh;
    font-family: "Roboto";
}

.inicioSubtitle{
    padding-top:  20px;
    text-align: justify;
    font-family: "Roboto";
    color: var(--color8);
    padding-bottom: 20px;
}

#inicio_content_img{
    padding-top: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
}

/*NOSOTROS STYLES*/
#nosotros_section{
    background-image: url("./images/background_r.png");
	background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
}

/*PRODUCTOS STYLES*/
#productos_section{
    background-image: url("./images/background_g.png");
	background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
}

.productosExclamation{
    padding-bottom: 25px;
    font-family: "Roboto"
}

#productos_content_container{
    padding-bottom: 20px;
}

.productosBoxButtons{
    display: flex;
    align-items: center;
    justify-content: center;

}

.bodyStyle{
    font-family: "Roboto"!important;
    font-weight: bold!important;
    border-radius: 0%!important;
    border-width: 0px!important;
    background-color: rgba(0, 0, 0, 0.0)!important;
    font-size: 20px !important;
    color: var(--color8)!important;

    align-content: center!important;
    text-align: center!important;
    align-items: center!important;
    justify-content: center!important;
}
.bodyStyle:hover{
    transform: scale(1.2);
    align-content: center!important;
    text-align: center!important;
    text-shadow: var(--color6) 0.1em 0.1em 0.4em;
}

/*MODAL*/
.modalHeaderQueso{ background-color: var(--color12); }
.modalHeaderFruta{ background-color: var(--color7); }
.modalHeaderLacteo{ background-color: var(--color4); }
.modalHeaderPan{ background-color: var(--color13); }
.modalHeaderChile{ background-color: var(--color14); }
.modalHeaderSeltzer{ background-color: var(--color15); }
.modalHeaderNew{ background-color: var(--color9); }



.modalProductTitle{
    color: var(--color8);
    font-family: "Roboto";
    font-weight: bold!important;
    font-size: 5vh!important;
}
.modalProductSubtitle{
    font-family: "Roboto";
    font-weight: bold;
    font-size: 3vh;
    color: var(--color8);
}

.radiusX{
    border-top-right-radius: 50px!important;
    border-bottom-left-radius: 50px!important;
}

.modalProductItem{
    font-family: "Roboto";
    color: var(--color8);
    display: flex;
    align-items: center;
    justify-content: left;
    top: 0px !important;
    padding-top: 0px !important;
    margin-top: 0px!important;
}
.modalProductItem div{
    background-size: 100%;
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.footerModalItem{
    font-family: "Roboto";
    color: var(--color8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerModalItem div{
    background-size: 100%;
    min-width: 35px;
    min-height: 35px;
    margin-right: 5px;
}



.bodyStyleQueso div{
    background-image: url("./images/t_queso.png");
    background-size: 100%;
    width: 60px;
    height: 60px;
}
#modal_body_queso .modalProductItem div{ background-image: url("./images/t_queso.png"); }


.bodyStyleFruta div{
    background-image: url("./images/t_fruta.png");
    background-size: 100%;
    width: 60px;
    height: 60px;
}
    
#modal_body_fruta .modalProductItem div{ background-image: url("./images/t_fruta.png"); }


.bodyStyleLacteo div{
    background-image: url("./images/t_lacteo.png");
    background-size: 100%;
    width: 60px;
    height: 60px;
}
#modal_body_lacteo .modalProductItem div{ background-image: url("./images/t_lacteo.png"); }


.bodyStylePan div{
    background-image: url("./images/t_pan.png");
    background-size: 100%;
    width: 60px;
    height: 60px;
}
#modal_body_pan .modalProductItem div{ background-image: url("./images/t_pan.png"); }


.bodyStyleChile div{
    background-image: url("./images/t_chile.png");
    background-size: 100%;
    width: 60px;
    height: 60px;
}
#modal_body_chile .modalProductItem div{ background-image: url("./images/t_chile.png"); }


.bodyStyleSeltzer div{
    background-image: url("./images/t_seltzer.png");
    background-size: 100%;
    width: 60px;
    height: 60px;
}
#modal_body_seltzer .modalProductItem div{ background-image: url("./images/t_seltzer.png"); }




#modal_product_list{
    overflow: auto;
    max-height: 70vh;

    border-style: solid;
    border-color: var(--color11);

    border-top-left-radius: 25px!important;
    border-bottom-left-radius: 25px!important;
}


.imageCarousel70{
    width: 100%;
    min-height: 70vh;
    max-height: 70vh;
    object-fit: cover;
}

.imageCarousel50{
    width: 100%;
    min-height: 50vh;
    max-height: 50vh;
    object-fit: cover;
}


/*SERVICIOS STYLES*/
#servicios_section{
    background-image: url("./images/background_p.png");
	background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
}

.circleDiv{
    padding-bottom: 20px;
}

.circleDiv:hover{
    transform: scale(1.01);
}

.circleDiv img{
    object-fit: cover;
    border-radius: 50%; 
    border: 10px solid var(--color8);  
    width: 90%;
    height: auto;
}

.circleDiv p{
    font-family: "Roboto";
    font-size: 3vh;
    font-weight: bold;
    color: var(--color8);
}



/*CONTACTO STYLES*/
#contacto_section{
	background-image: url("./images/contacto_background.png");
	background-position: 0px bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
    padding-bottom:50px;
}

#contacto_form{
    padding-top: 20px;
}

.contactoSubtitle{
    text-align: center;
    font-weight: 400;
    font-size: 4vh;
    color: var(--color8);
}

.privacityText{
    text-align: justify;
}

.contactoSMLogo{
    width: 50px;
    height: 50px;
}
.contactoSMLogo:hover{
    transform: scale(1.4);
}

#contact_social_media{
    padding-bottom: 30px;
}

/*FOOTER STYLES*/
#footer_section{
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: rgba(255,255,255,0.9);
    background-image: url("./images/background_b.png");
	background-position: 0px 0px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 10vh;
    z-index:1;
}

.contactoFooterLogo{
    width: 50px;
    height: 50px;
}
.contactoFooterLogo:hover{
    transform: scale(1.4);
}

#footer_social{
    display: flex!important;
    align-items: left!important;
    text-align: left!important;
}

.footerSubtitle{
    font-family: "Roboto";
    font-weight: bold;
    font-size: 2vh;
    color: var(--color8);
    text-align-last: left;
}

.footerCopyright{
    font-family: "Roboto";
    font-weight: bold;
    font-size: 2vh;
    color: var(--color8);
    text-align-last: center;
    padding: 10px;
    margin: 0px;
}

.footer_title{
    font-size: 3vh;
    font-family: "Roboto";
    font-weight: bold;
    color: var(--color8);
    text-align-last: center;
}

.footerText{
    font-size: 2vh;
    font-family: "Roboto";
    color: var(--color8);
}
.footerLink{
    font-size: 2vh;
    font-family: "Roboto";
    font-weight: bold;
    color: var(--color3);
}

.footerItem{
    font-family: "Roboto";
    color: var(--color8);
    display: flex;
    align-items: center;
    justify-content: left;
    top: 0px !important;
    padding-top: 0px !important;
    margin-top: 0px!important;
}

.footerItem div{
    background-size: 100%;
    min-width: 35px;
    min-height: 35px;
    margin-right: 5px;
}

.footerAddressItem{ background-image: url("./images/t_address.png"); }
.footerTelephoneItem{ background-image: url("./images/t_telephone.png"); }
.footerEmailItem{ background-image: url("./images/t_email.png"); }


/**/
.cardASD {
    margin-top: 50px;
    position: relative;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 8px 2.2px var(--color5), 0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.025), 0 22.3px 17.9px rgba(0, 0, 0, 0.03), 0 41.8px 33.4px rgba(0, 0, 0, 0.036);
}
.cardASD--image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.cardASD--image img {
    width: 140%;
    height: 140%;
    object-fit: cover;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardASD--info {
    --size: 20px;
    width: var(--size);
    height: var(--size);
    background: rgba(0, 0, 0, 0.0);
    border-radius: 10px;
    position: absolute;
    transition: width 550ms ease, height 550ms ease, right 550ms ease, background 550ms ease;
    overflow: hidden;
    /*
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.014), 0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.025), 0 22.3px 17.9px rgba(0, 0, 0, 0.03), 0 41.8px 33.4px rgba(0, 0, 0, 0.036), 0 100px 80px rgba(0, 0, 0, 0.05), 0 0px 2.2px rgba(0, 0, 0, 0.045), 0 0px 5.3px rgba(0, 0, 0, 0.065), 0 0px 10px rgba(0, 0, 0, 0.08), 0 0px 17.9px rgba(0, 0, 0, 0.095), 0 0px 33.4px rgba(0, 0, 0, 0.115), 0 0px 80px rgba(0, 0, 0, 0.16), 0 0.2px 2.2px rgba(0, 0, 0, 0.014), 0 0.6px 5.3px rgba(0, 0, 0, 0.02), 0 1.1px 10px rgba(0, 0, 0, 0.025), 0 2px 17.9px rgba(0, 0, 0, 0.03), 0 3.8px 33.4px rgba(0, 0, 0, 0.036), 0 9px 80px rgba(0, 0, 0, 0.05);
    */
}
.cardASD--info::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(--color3) 0%, rgba(0, 0, 0, 0.25) 50%, transparent 100%);
    z-index: 1;
    opacity: 0;
    transition: opacity 550ms ease;
}
.cardASD--info.info--1 {
    top: 5%;
    left: -10%;}
.cardASD--info.info--1 .info--image img {
    top: -0%;
    left: -120%;}

.cardASD--info.info--2 {
    top: 50%;
    right: 10%;}
.cardASD--info.info--2 .info--image img {
    top: -80%;
    left: -120%;}

.cardASD--info.info--3 {
    bottom: 5%;
    left: -10%;}
.cardASD--info.info--3 .info--image img {
    bottom: -0%;
    left: -120%;}

.cardASD--info .icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardASD--info .icon ion-icon {
    color: white;
}
.cardASD--info .info--text {
    display: block;
    position: absolute;
    left: 10%;
    bottom: 5%;
    font-size: 0.55rem;
    letter-spacing: 1px;
    font-family: "Roboto";
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
    z-index: 2;
    opacity: 0;
    transform: translateY(25%);
    transition: opacity 250ms ease, transform 250ms ease;
}
.cardASD--info .info--image {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.cardASD--info .info--image img {
    position: absolute;
    width: 340%;
    height: 340%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 550ms ease;
}
.cardASD:hover .cardASD--info {
    --size: 100px;
}
.cardASD:hover .cardASD--info::after {
    opacity: 1;
}
.cardASD:hover .cardASD--info.info--1 {
    left: -10%;
}
.cardASD:hover .cardASD--info.info--2 {
    right: -10%;
}
.cardASD:hover .cardASD--info.info--3 {
    left: -10%;
}
.cardASD:hover .cardASD--info .info--image img {
    opacity: 1;
}
.cardASD:hover .cardASD--info .info--text {
    transition-delay: 550ms;
    opacity: 1;
    transform: translateY(0%);
}



/**/
.containerProductImg {
    display: flex;
    padding: 0 0;
    height: 35vh;
}
  
.boxProductImg {
    overflow: hidden;
    transition: 0.5s;
    margin: 0 0%;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0);
    border-top-right-radius: 50px!important;
    border-bottom-left-radius: 50px!important;
}
  
.boxProductImg > img {
    width: 120%;
    height: calc(100% - 10vh);
    object-fit: cover;
    transition: 0.5s;
}
  
.boxProductImg > span {
    font-size: 2.6vh;
    display: block;
    text-align: center;
    height: 10vh;
    line-height: 2.6;
    font-family: "Roboto"!important;
    font-weight: bold!important;
}
  
.boxProductImg:hover {
    flex: 1 1 50%;
    border-bottom-left-radius: 50px!important;
}
.boxProductImg:hover > img {
    width: 120%;
    height: 100%;
    border-bottom-left-radius: 50px!important;
  }

  

/**/

  
.boxImageNMV {
    font-family: "Roboto";
    position: relative;
    overflow: hidden;
    width: 100%;
    color: #141414;
    text-align: left;
    line-height: 1.4em;
    font-size: 16px;
    
  }
  .boxImageNMV * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }
  .boxImageNMV img {
    width: 103%;
    vertical-align: top;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    border: 5px solid var(--color3);

  }
  .boxImageNMV figcaption {
    position: absolute;
    top: calc(80%);
    max-width: 100%;
    background-color: var(--color3);
    height: 100%;
    padding: 15px 15px 150px;
  }
  .boxImageNMV figcaption:before {
    position: absolute;
    content: "";
    z-index: 2;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 30px;
    background-image: -webkit-linear-gradient(top, transparent 0%, var(--color3) 100%);
    background-image: linear-gradient(to bottom, transparent 0%, var(--color3) 100%);
    
  }
  .boxImageNMV h3,
  .boxImageNMV p {
    margin: 0 0 10px;
  }
  .boxImageNMV h3 {
    font-weight: bold;
    font-size: 1.4em;
    line-height: 1.2em;
    font-family: "Roboto";
    color: var(--color16);
    text-transform: uppercase;
  }
  .boxImageNMV p {
    font-size: 0.9em;
    letter-spacing: 1px;
    font-family: "Roboto";
    color: var(--color16);
  }
  .boxImageNMV:hover figcaption,
  .boxImageNMV.hover figcaption {
    top: 60px;
  }



  /**/

  

  .containerP {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3rem;
  padding: 2rem 4rem 4rem;
}
.containerP .wrapper {
  transform: skew(-10deg);
  transition: 0.25s;
}
.containerP .wrapper:before {
  content: '';
  left: 0;
  top: 0;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  border-radius: 40px;
  opacity: 0;
  border: 2px solid #ece9e6;
  position: absolute;
  z-index: -1;
  transition: 0.5s 0.05s;
}
.containerP .wrapper:hover:before {
  opacity: 1;
  transform: translateY(1.5rem) translateX(1.5rem);
}
.containerP .wrapper:nth-child(1) .card:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://images.pexels.com/photos/953219/pexels-photo-953219.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-color: #373f51;
  background-blend-mode: screen;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}
.containerP .wrapper:nth-child(2) .card:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://images.pexels.com/photos/316891/pexels-photo-316891.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-color: #373f51;
  background-blend-mode: screen;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}
.containerP .wrapper:nth-child(3) .card:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://images.pexels.com/photos/3303/water-drink-fresh-lemons.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-color: #373f51;
  background-blend-mode: screen;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}
.containerP .wrapper:nth-child(4) .card:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://images.pexels.com/photos/635537/pexels-photo-635537.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-color: #373f51;
  background-blend-mode: screen;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}
.containerP .wrapper:nth-child(5) .card:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://images.pexels.com/photos/6478/tree-lemon-fruit.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-color: #373f51;
  background-blend-mode: screen;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}
.containerP .wrapper:nth-child(6) .card:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://images.pexels.com/photos/709816/pexels-photo-709816.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-color: #373f51;
  background-blend-mode: screen;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}
.containerP .wrapper:hover {
  transform: translateX(-1rem) translateY(-1rem) skew(-10deg);
}
.containerP .wrapper:hover .card {
  box-shadow: 0.5rem 0.5rem 1rem rgba(0,0,0,0.1);
}
.containerP .wrapper:hover .card:after {
  background-blend-mode: normal;
}
.containerP .wrapper:hover .card .fal {
  bottom: 4rem;
  background-color: rgba(255,255,255,0.8);
}
.containerP .card {
  background-color: #fff;
  border-radius: 40px;
  height: 15rem;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}
.containerP .card:after {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.containerP .card .fal {
  position: absolute;
  bottom: -1rem;
  right: 3rem;
  border-radius: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  color: #373f51;
  z-index: 1;
  transform: skew(10deg) translateY(50%);
  text-align: center;
  transition: 0.25s;
}